@font-face {
  font-family: "Garamond Premier Pro";
  src: url("/assets/fonts/GaramondPremrPro-It.woff2") format("woff2"), url("/assets/fonts/GaramondPremrPro-It.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Garamond Premiere Pro";
  src: url("/assets/fonts/GaramondPremrPro-Smbd.woff2") format("woff2"), url("/assets/fonts/GaramondPremrPro-Smbd.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Myriad Web Pro";
  src: url("/assets/fonts/MyriadWebPro-Italic.woff2") format("woff2"), url("/assets/fonts/MyriadWebPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Garamond Premiere Pro";
  src: url("/assets/fonts/GaramondPremrPro.woff2") format("woff2"), url("/assets/fonts/GaramondPremrPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Myriad Web Pro";
  src: url("/assets/fonts/MyriadWebPro-Bold.woff2") format("woff2"), url("/assets/fonts/MyriadWebPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Myriad Web Pro";
  src: url("/assets/fonts/MyriadWebPro.woff2") format("woff2"), url("/assets/fonts/MyriadWebPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Garamond Premier Pro";
  src: url("/assets/fonts/GaramondPremrPro-SmbdIt.woff2") format("woff2"), url("/assets/fonts/GaramondPremrPro-SmbdIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}